import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Product } from '../../components/products/Product';
import React from 'react';
import WrapperComponent from '../../components/shared/Wrapper';

const works = [
  {
    id: 1,
    title: 'SaaS Project Management Tool',
    tags: ['Graphic Design'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
  {
    id: 2,
    title: 'Mobile Banking App',
    tags: ['UI/UX'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
  {
    id: 3,
    title: 'Tool for Task Management ',
    tags: ['Motion Design'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
  {
    id: 4,
    title: 'Hospital and Healthcare Website',
    tags: ['Web Design'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
];

export const Works = () => {
  const breadcrumbs = [{ name: 'Our works', isActive: true }];
  const WorkGroup = () => {
    const productGroup = works.map((product, index) => {
      return <Product key={index} product={product} placement={index % 2 === 0 ? 'Right' : 'Left'} />;
    });

    return <div>{productGroup}</div>;
  };

  return (
    <WrapperComponent>
      <div className="container">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Our works</h1>
      </div>
      <section className="container pb-2 pb-lg-3" style={{ paddingTop: '100px' }}>
        <WorkGroup />
      </section>
    </WrapperComponent>
  );
};
