import './app.scss';

import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { About } from './pages/about/About';
import { Contact } from './pages/contacts/Contact';
import { Home } from './pages/home/Home';
import LoginComponent from './pages/login/login';
import { Products } from './pages/products/Products';
import { Work } from './pages/works/Work';
import { Works } from './pages/works/Works';

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LoginComponent />} />
          <Route path="/en" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/our-products" element={<Products />} />
          <Route path="/our-works" element={<Works />} />
          <Route path="/our-works/work" element={<Work />} />
          <Route path="/contact-us" element={<Contact />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
