import '../pages.scss';

import { Clients } from '../../components/clients/Clients';
import { Link } from 'react-router-dom';
import React from 'react';
import { Services } from '../../components/services/Services';
import { Works } from '../../components/works/Works';
import WrapperComponent from '../../components/shared/Wrapper';

export const Home = () => {
  return (
    <WrapperComponent>
      <Hero />
      <Services />
      <Works />
      <Clients />
    </WrapperComponent>
  );
};

export const Hero = () => {
  return (
    <div style={{ backgroundColor: 'gray', marginBottom: '60px' }} className="header">
      <section
        className="jarallax position-relative d-flex align-items-center"
        data-jarallax=""
        data-img-position="0% 100%"
        data-speed="0.5"
      >
        <div className="container position-relative zindex-5">
          <div className="row justify-content-md-start justify-content-center">
            <div className="col-md-6 col-sm-8 order-md-1 order-2 d-flex flex-column justify-content-between mt-4 pt-2 text-md-start text-center">
              <div className="mb-md-5 pb-xl-5 mb-4">
                <div className="d-inline-flex align-items-center position-relative mb-3 hero">
                  <b className="mb-0" style={{ fontWeight: '600', fontSize: '16px', paddingTop: '30px' }}>
                    Software Solutions Agency
                  </b>
                </div>

                <h1 className="display-2 mb-md-5">
                  We <span className="text-gradient-primary">Transform</span> Your Ideas into Reality
                </h1>
                <div className="d-md-flex align-items-md-start mb-md-3 pb-xl-2">
                  <p className="d-lg-block d-none mb-0">
                    Ounic is a premier full-service digital agency based in Nairobi. We create AI Agents, mobile apps,
                    web-based systems, and brands that resonate with users globally, delivering impactful and
                    user-centered digital experiences.
                  </p>
                </div>
                <div className="d-md-flex align-items-md-start">
                  <Link to="/contact-us" className="btn btn-lg btn-primary flex-shrink-0 me-md-4 mb-md-0 mb-sm-4 mb-3">
                    Work with us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
