import './about.scss';

import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Padding } from '../../components/padding/Padding';
import { People } from '../../components/people/People';
import { Person } from '../../components/people/person/Person';
import React from 'react';
import WrapperComponent from '../../components/shared/Wrapper';
import { shimanyi } from '../../common/people';

const breadcrumbs = [
  {
    name: 'About us',
    isActive: true,
  },
];

const people = [
  {
    name: 'Faith Miriam',
    role: 'Data Analyst',
    // image: '',
    online: {
      linkedin: 'https://www.linkedin.com/',
      github: '',
    },
  },
  {
    name: 'Hope Ibrahim',
    role: 'Software Engineer',
    // image: '',
    online: {
      linkedin: 'https://www.linkedin.com/',
      github: '',
    },
  },
  {
    name: 'Moses Mutuku',
    role: 'Sofware Engineer',
    // image: '',
    online: {
      linkedin: 'https://www.linkedin.com/',
      github: '',
    },
  },
  {
    name: 'Shimanyi Valentino',
    role: 'Advisor',
    image: shimanyi,
    online: {
      linkedin: 'https://www.linkedin.com/in/shimanyi',
      github: '',
    },
  },
];

export const About = () => {
  return (
    <WrapperComponent>
      <section className="container position-relative ">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div
          className="position-absolute top-0 start-0 w-100 bg-position-bottom-center bg-size-cover bg-repeat-0"
          style={{ backgroundImage: ' url(assets/img/about/hero-bg.svg)' }}
        >
          <div className="d-lg-none" style={{ height: '960px' }}></div>
          <div className="d-none d-lg-block" style={{ height: '768px' }}></div>
        </div>

        <div className="position-relative zindex-5 pt-5 pb-lg-5">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="pb-2 pb-md-3">About Ounic</h1>
              <p className="fs-xl pb-4 mb-1 mb-md-2 mb-lg-3" style={{ maxWidth: '526px' }}>
                We are a dedicated team of passionate product managers, full stack developers, UX/UI designers, QA
                engineers and marketing experts helping businesses of every size — from new startups to public companies
                — launch their projects using our software.
              </p>
              {/* <img src="assets/img/about/clutch-dark.png" className=" d-dark-mode-none" width="175" alt="Clutch" />
            <img
              src="assets/img/about/clutch-light.png"
              className="d-none d-dark-mode-block"
              width="175"
              alt="Clutch"
            /> */}
              <div className="row row-cols-3 pt-4 pt-md-5 mt-2 mt-xl-4">
                <div className="col">
                  <h3 className="h2 mb-2">10</h3>
                  <p className="mb-0">
                    <strong>Clients</strong>
                  </p>
                </div>
                <div className="col">
                  <h3 className="h2 mb-2">20</h3>
                  <p className="mb-0">
                    <strong>Projects</strong>
                  </p>
                </div>
                <div className="col">
                  <h3 className="h2 mb-2">10</h3>
                  <p className="mb-0">
                    <strong>Engineers</strong>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-xl-3 pt-5 pt-lg-4">
              <div className="row row-cols-2 gx-3 gx-lg-4">
                <div className="col pt-lg-5 mt-lg-1">
                  <img src="https://placehold.co/300x300" className="d-block rounded-3 mb-3 mb-lg-4" alt="Image" />
                  <img src="https://placehold.co/300x300" className="d-block rounded-3" alt="Image" />
                </div>
                <div className="col">
                  <img src="https://placehold.co/300x300" className="d-block rounded-3 mb-3 mb-lg-4" alt="Image" />
                  <img src="https://placehold.co/300x300" className="d-block rounded-3" alt="Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Padding padding={'80px'} />
      <div className="pt-5 pb-5 bg-secondary">
        <section className="container pb-2 pb-md-4 pb-lg-5" id="benefits">
          <div className="row">
            <div
              className="col swiper-slide border-end-lg px-2 swiper-slide-active"
              role="group"
              aria-label="1 / 3"
              style={{ width: '432px' }}
            >
              <div className="text-center">
                {/* <img src={sketch} alt="Software Consultancy" width="70" className="d-block mb-4 mx-auto" /> */}
                <h4 className="mb-2 pb-1">Digital Skills Training</h4>
                <p className="mx-auto" style={{ maxWidth: '336px' }}>
                  Help individuals and teams master the tools and technologies that drive success.
                </p>
              </div>
            </div>

            <div
              className="col swiper-slide border-end-lg px-2 swiper-slide-active"
              role="group"
              aria-label="1 / 3"
              style={{ width: '432px' }}
            >
              <div className="text-center">
                {/* <img src={coding} alt="" width="70" className="d-block mb-4 mx-auto" /> */}
                <h4 className="mb-2 pb-1">Software Consultancy</h4>
                <p className="mx-auto" style={{ maxWidth: '336px' }}>
                  Help businesses and teams navigate complex challenges, optimize processes, and leverage the latest
                  technologies for sustained success.
                </p>
              </div>
            </div>

            <div
              className="col swiper-slide px-2 swiper-slide-active"
              role="group"
              aria-label="1 / 3"
              style={{ width: '432px' }}
            >
              <div className="text-center">
                {/* <img src={ecology} alt="" width="70" className="d-block mb-4 mx-auto" /> */}
                <h4 className="mb-2 pb-1">Products Development</h4>
                <p className="mx-auto" style={{ maxWidth: '336px' }}>
                  Turning your ideas into scalable, sustainable, market-ready solutions.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="container py-5 my-md-3 my-lg-5">
        <h2 className="h1 text-center pt-1 pb-3 mb-3 mb-lg-4">Our People</h2>
        <People people={people} />
      </section>
    </WrapperComponent>
  );
};
