import './wrapper.scss';

import React, { ReactNode } from 'react';

import FooterComponent from './Footer';
import HeaderComponent from './Header';

type WrapperProps = {
  children: ReactNode;
};

export const WrapperComponent: React.FC<WrapperProps> = (props) => {
  return (
    <div>
      <HeaderComponent />
      <div className="padding-top">{props.children}</div>
      <FooterComponent />
    </div>
  );
};

export default WrapperComponent;
