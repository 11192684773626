import { Product } from './Product.types';
import { Product as ProductComponent } from './Product';
import React from 'react';

interface ProductListProps {
  products: Product[];
}

export const Products: React.FC<ProductListProps> = (props) => {
  const productGroup = props.products.map((product, index) => {
    return <ProductComponent key={index} product={product} placement={index % 2 === 0 ? 'Right' : 'Left'} />;
  });

  return <div>{productGroup}</div>;
};
