import { Link } from 'react-router-dom';
import { ProductProps } from './Product.types';
import React from 'react';

export const Product: React.FC<ProductProps> = ({ product, placement }) => {
  return (
    <div className="row pb-5 mb-md-4 mb-lg-5">
      <div
        className={
          placement == 'Right'
            ? 'rellax col-md-6 pb-1 mb-3 pb-md-0 mb-md-0'
            : 'rellax col-md-6 order-md-2 pb-1 mb-3 pb-md-0 mb-md-0'
        }
      >
        <a href={product.link}>
          <img src={product.image} className="rounded-3" width="600" alt="Image" />
        </a>
      </div>
      <div
        className="rellax col-md-6"
        data-rellax-percentage="0.5"
        data-rellax-speed="-0.6"
        data-disable-parallax-down="md"
      >
        <div className="ps-md-4 ms-md-2">
          <div className="fs-sm text-muted mb-1">{product.date.toLocaleDateString()}</div>
          <h2 className="h3">{product.title}</h2>
          {/* TODO(): render tags */}
          <a href="#" className="d-table badge bg-faded-primary text-primary fs-sm mb-3">
            {product.tags[0]}
          </a>
          <p className="d-md-none d-lg-block pb-3 mb-2 mb-md-3">{product.description}</p>
          {/* TODO(): link to product */}
          <Link to="/our-works/work" className="btn btn-outline-primary">
            View more
          </Link>
        </div>
      </div>
    </div>
  );
};
