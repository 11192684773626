import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Padding } from '../../components/padding/Padding';
import React from 'react';
import { Services } from '../../components/services/Services';
import WrapperComponent from '../../components/shared/Wrapper';

export const Contact = () => {
  return (
    <WrapperComponent>
      <div className="container">
        <Breadcrumbs breadcrumbs={[{ name: 'Contact us', isActive: true }]} />
        <h1>Contact us</h1>
      </div>
      <Padding />
      <Services />
    </WrapperComponent>
  );
};
