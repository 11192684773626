import React from 'react';
import codeofafrica from '../../common/customers/codeofafrica.svg';
import coding from '../../common/icons/coding.png';
import ecology from '../../common/icons/ecology.png';
import giz from '../../common/customers/giz.png';
import sketch from '../../common/icons/sketch.png';
import strathmore from '../../common/customers/strathmore.png';

export const Services = () => {
  return (
    <section className="container mb-5 pb-2 pb-md-4 pb-lg-5" id="benefits">
      <div className="row">
        <div
          className="col swiper-slide border-end-lg px-2 swiper-slide-active"
          role="group"
          aria-label="1 / 3"
          style={{ width: '432px' }}
        >
          <div className="text-center">
            <img src={sketch} alt="Software Consultancy" width="70" className="d-block mb-4 mx-auto" />
            <h4 className="mb-2 pb-1">Digital Skills Training</h4>
            <p className="mx-auto" style={{ maxWidth: '336px' }}>
              For individuals and teams mastering the tools and technologies that drive success.
            </p>
          </div>
        </div>

        <div
          className="col swiper-slide border-end-lg px-2 swiper-slide-active"
          role="group"
          aria-label="1 / 3"
          style={{ width: '432px' }}
        >
          <div className="text-center">
            <img src={coding} alt="" width="70" className="d-block mb-4 mx-auto" />
            <h4 className="mb-2 pb-1">Software Consultancy</h4>
            <p className="mx-auto" style={{ maxWidth: '336px' }}>
              Support businesses and teams navigate complex challenges, optimize processes, and leverage the latest
              technologies for sustained success.
            </p>
          </div>
        </div>

        <div
          className="col swiper-slide px-2 swiper-slide-active"
          role="group"
          aria-label="1 / 3"
          style={{ width: '432px' }}
        >
          <div className="text-center">
            <img src={ecology} alt="" width="70" className="d-block mb-4 mx-auto" />
            <h4 className="mb-2 pb-1">Products Development</h4>
            <p className="mx-auto" style={{ maxWidth: '336px' }}>
              Turning your ideas into scalable, sustainable, market-ready solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
