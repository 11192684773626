import { Link } from 'react-router-dom';
import { PortfolioCard } from '../portfolio/PortfolioCard';
import { RoleCard } from '../person/RoleCard';
import blog from '../common/blog/blog-01.jpeg';

enum ArticleType {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

const SamplePortfolioCards = [
  {
    articleType: ArticleType.Horizontal,
    image: 'https://silicon.createx.studio/assets/img/landing/digital-agency/blog/01.jpg',
    tags: ['Digital'],
    title: 'A study on smartwatch design qualities and people’s preferences',
    date: '12 hours ago',
    articleLink: '/',
    authorCard: <RoleCard name="Jerome Bell" role="Marketing Specialist" />,
  },
  {
    articleType: ArticleType.Vertical,
    image: 'https://silicon.createx.studio/assets/img/landing/digital-agency/blog/02.jpg',
    tags: ['Digital'],
    title: 'A study on smartwatch design qualities and people’s preferences',
    date: '12 hours ago',
    articleLink: '/',
    authorCard: <RoleCard name="Jerome Bell" role="Marketing Specialist" />,
  },
  {
    articleType: ArticleType.Vertical,
    image: 'https://silicon.createx.studio/assets/img/landing/digital-agency/blog/02.jpg',
    tags: ['Digital'],
    title: 'A study on smartwatch design qualities and people’s preferences',
    date: '12 hours ago',
    articleLink: '/',
    authorCard: <RoleCard name="Jerome Bell" role="Marketing Specialist" />,
  },
];

export const Works = () => {
  const FeatureTopCard = () => {
    return <PortfolioCard {...SamplePortfolioCards[0]} />;
  };
  const FeatureSecondaryCard = () => {
    const articles = SamplePortfolioCards.slice(1);

    const FeatureSecondaryCardGroup = articles.map((article, index) => (
      <div className="row">
        <div className={index === 0 ? 'mb-4' : ''}>
          <PortfolioCard {...article} />
        </div>
      </div>
    ));

    return <>{FeatureSecondaryCardGroup}</>;
  };

  return (
    <div style={{ backgroundColor: '#f3f6ff', padding: '60px 40px' }}>
      <section className="container pb-lg-5">
        <h2 className="h1 text-center">Our works</h2>
        <p className="mb-4 mx-auto pb-lg-5 fs-lg text-center" style={{ maxWidth: '636px' }}>
          We specialize in research, web systems and mobile app development, web design, UX/UI design, and custom
          illustrations.
        </p>
        <div className="row">
          <div className="col-lg-5 col-12 mb-lg-0">
            <PortfolioCard {...SamplePortfolioCards[0]} />
          </div>
          <div className="col">
            <FeatureSecondaryCard />
          </div>
        </div>
      </section>
    </div>
  );
};
