import * as React from 'react';

type RoleCardProps = {
  name: string;
  role: string;
  image?: string;
};

export const RoleCard: React.FC<RoleCardProps> = (props) => {
  return (
    <div className="d-inline-flex align-items-center position-relative me-3">
      <img
        src={props.image ?? 'https://silicon.createx.studio/assets/img/avatar/08.jpg'} // Fix(): Add default image; initials of the names
        className="rounded-circle me-3"
        width="48"
        alt="Avatar"
      />
      <div>
        <span className="nav-link p-0 fw-bold text-decoration-none stretched-link">{props.name}</span>
        <span className="fs-sm text-muted">{props.role}</span>
      </div>
    </div>
  );
};
