import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Padding } from '../../components/padding/Padding';
import React from 'react';
import WrapperComponent from '../../components/shared/Wrapper';

export const Work = () => {
  const breadcrumbs = [
    { name: 'Portfolio', isActive: false },
    { name: 'Single Project', isActive: true },
  ];

  return (
    <WrapperComponent>
      <div className="container">
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <section className=" pb-4 mb-2 mb-lg-3">
          <h1>Single Project 3D Rendering of Human Sculpture</h1>
          <p className="text-muted mb-0">NTF / Graphic Design / Art / Identity / Motion Design </p>
        </section>

        <section className="jarallax" data-jarallax="" data-speed="0.5">
          <div className="d-none d-xxl-block" style={{ height: '500px' }}></div>
          <div className="d-none d-lg-block d-xxl-none" style={{ height: '600px' }}></div>
          <div className="d-none d-md-block d-lg-none" style={{ height: '450px' }}></div>
          <div className="d-md-none" style={{ height: '400px' }}></div>
          <img className="jarallax-img" src="https://placehold.co/1800x600" alt="Single Project" />
        </section>

        <section className=" py-5 my-1 my-md-4 my-lg-5">
          <div className="row">
            <div className="col-lg-7 mb-4 mb-lg-0">
              <div className="pe-lg-4 me-lg-3 pe-xl-0 me-xl-0">
                <h2 className="h1 mb-4">About</h2>
                <p className="fs-lg mb-0">
                  Lacinia pulvinar at diam, urna, non blandit. Cras id enim tortor nascetur. Cursus ante eu nam ut non
                  vestibulum sem. Ullamcorper quis varius eu, vel. Sagittis ut suspendisse et, nec. Parturient eu
                  iaculis sit dolor, erat mauris. Leo at egestas aliquet duis pellentesque amet. Proin mattis ac ornare
                  malesuada sed. Diam libero tortor suspendisse molestie non duis enim. Lectus pulvinar euismod et risus
                  egestas. Cursus porttitor id faucibus eu vestibulum. Eu blandit faucibus nulla adipiscing amet
                  ullamcorper.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4 offset-xl-1 border-start-lg">
              <div className="ps-lg-4 ms-lg-3">
                <h3 className="h5 d-flex align-items-center">
                  <i className="bx bx-help-circle text-primary fs-4 me-2"></i>
                  Challenge
                </h3>
                <p className="pb-4 mb-0 mb-lg-3">
                  Ultricies massa iaculis vestibulum egestas vestibulum, cursus. Leo non arcu ultricies senectus a purus
                  sed.
                </p>
                <h3 className="h5 d-flex align-items-center">
                  <i className="bx bx-bulb text-primary fs-4 me-2"></i>
                  Solution
                </h3>
                <p className="mb-0">
                  Sit tellus id proin viverra iaculis pellentesque ornare. Diam augue sit feugiat porttitor leo, fusce
                  vel ac. Placerat vulputate quisque.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className=" py-5 my-2 my-md-4 my-lg-5">
          <div className="row py-md-3">
            <div className="col-md-3 offset-lg-1">
              <h2>Results</h2>
            </div>
            <div className="col-lg-7 col-md-9">
              <p className="fs-lg pb-4 mb-2 mb-lg-3">
                Vitae eget in vulputate quam. Turpis habitasse egestas vitae, sed tortor, nunc. Enim, magna duis lectus
                mauris magna orci, nunc. Tincidunt ultrices mauris, sed quisque. Vulputate volutpat egestas facilisi
                morbi proin sagittis. Ipsum eleifend commodo eu, at et adipiscing. Viverra turpis sem in tincidunt
                viverra aliquet eleifend. Adipiscing elit, donec volutpat ante aliquet imperdiet quis hendrerit.
              </p>
              <div className="row row-cols-1 row-cols-sm-3 g-4">
                <div className="col">
                  <h3 className="h1 mb-2">+1,200</h3>
                  <p className="mb-0">
                    <span className="fw-semibold">Requests</span> per Week
                  </p>
                </div>
                <div className="col">
                  <h3 className="h1 mb-2">+760</h3>
                  <p className="mb-0">
                    <span className="fw-semibold">New Clients</span> per Month
                  </p>
                </div>
                <div className="col">
                  <h3 className="h1 mb-2">+480</h3>
                  <p className="mb-0">
                    <span className="fw-semibold">Remote</span> Sales Experts
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-secondary">
          <div className=" py-2 py-md-4 py-lg-5">
            <div className="row">
              <div className="col-md-4">
                <div className="position-relative d-flex h-100">
                  <div
                    className="position-absolute top-0 start-0 w-100 h-100 rounded-3 bg-size-cover bg-repeat-0 bg-position-center"
                    style={{ backgroundImage: 'url(assets/img/testimonials/03.jpg)' }}
                  ></div>
                </div>
              </div>
              <div className="col-md-6">
                <figure className="card position-relative border-0 shadow-sm py-3 p-0 p-lg-4 mt-4 mb-0 ms-xl-5">
                  <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4 ms-lg-5">
                    <i className="bx bxs-quote-left"></i>
                  </span>
                  <blockquote className="card-body mt-2 mb-0">
                    <p className="fs-lg">
                      Dolor, a eget elementum, integer nulla volutpat, nunc, sit. Quam iaculis varius mauris magna sem.
                      Egestas sed sed suscipit dolor faucibus dui imperdiet at eget. Tincidunt imperdiet quis hendrerit
                      aliquam feugiat neque cras sed. Dictum quam integer volutpat tellus, faucibus platea. Pulvinar
                      turpis proin faucibus at mauris.
                    </p>
                  </blockquote>
                  <figcaption className="card-footer border-0 d-sm-flex pt-0 mt-n3 mt-lg-0">
                    <div className="d-flex align-items-center border-end-sm pe-sm-4 me-sm-4">
                      <img
                        src="assets/img/avatar/05.jpg"
                        width="48"
                        className="d-md-none rounded-circle"
                        alt="Ralph Edwards"
                      />
                      <div className="ps-3 ps-md-0">
                        <h5 className="fw-semibold lh-base mb-0">Ralph Edwards</h5>
                        <span className="fs-sm text-muted">Head of Marketing</span>
                      </div>
                    </div>
                    <img
                      src="assets/img/brands/02.svg"
                      width="160"
                      className="d-block mt-2 ms-5 mt-sm-0 ms-sm-0"
                      alt="Company logo"
                    />
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Padding />
    </WrapperComponent>
  );
};
