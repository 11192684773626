import React from 'react';
import { useNavigate } from 'react-router-dom';

const LoginComponent = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const nav = useNavigate();

  const login = () => {
    if (email === 'dev@ounic.co' && password === 'admin@123') {
      setIsLoggedIn(true);
      nav('/en');
    } else {
      nav('/');
    }
  };

  const handSubmit = (event: any) => {
    event.preventDefault();
  };

  return !isLoggedIn ? (
    <main
      className="form-signin w-100 m-auto"
      style={{
        maxWidth: '330px',
        padding: '15px',
        margin: 'auto',
        paddingTop: '20%',
      }}
    >
      <form onSubmit={handSubmit}>
        <div>
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="e@e.co"
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label>
        </div>
        <button className=" w-100 btn  btn-primary" type="submit" onClick={login}>
          Sign in
        </button>
      </form>
    </main>
  ) : null;
};

export default LoginComponent;
