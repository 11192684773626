import './wrapper.scss';

import { Link } from 'react-router-dom';
import React from 'react';
import ounic from '../../common/brand/ounic.svg';

const HeaderComponent = () => {
  const [isSticky, setIsSticky] = React.useState(false);
  const header = document.getElementById('header');
  const sticky = header?.offsetHeight;

  React.useEffect(() => {
    window.addEventListener('scroll', onScrolling);
    return () => {
      window.removeEventListener('scroll', onScrolling);
    };
  });

  const onScrolling = (e: Event) => {
    sticky && window.pageYOffset > sticky ? setIsSticky(true) : setIsSticky(false);
  };

  return (
    <header
      className={
        isSticky
          ? 'navbar-stuck header navbar navbar-expand-lg navbar-light position-absolute'
          : 'header navbar navbar-expand-lg navbar-light position-absolute'
      }
      style={{ height: '80px', position: 'fixed' }}
      id={'header'}
    >
      <div className="container px-3">
        <Link to="/en" className="navbar-brand pe-3">
          <img src={ounic} alt="Ounic Logo" width={100} />
        </Link>
        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/en" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about-us" className="nav-link">
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/our-products" className="nav-link">
                  Our products
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/our-works" className="nav-link">
                  Our works
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact-us" className="nav-link">
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
