import './footer.scss';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ounic from '../../common/brand/ounic.svg';

const FooterComponent = () => {
  const url = window.location.href;
  const isConctactPage = url.includes('contact-us');

  return (
    <>
      <footer className="footer pt-5 pb-4 pb-lg-5 mt-2 mt-md-0 background">
        <div className="container pt-lg-4">
          <div className="row pb-5">
            <div className="col-lg-4 col-md-6">
              <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                <img src={ounic} alt="Ounic Logo" width={80} />
              </div>
              <p className="fs-sm pb-lg-3 mb-4">
                Empowering innovation with cutting-edge solutions. We specialize in delivering tailored products and
                services that drive efficiency, growth, and success for businesses worldwide.
              </p>
              <form className="needs-validation">
                <label className="form-label">Subscribe to our newsletter</label>
                <div className="input-group">
                  <input
                    type="email"
                    id="subscr-email"
                    className="form-control rounded-start ps-5"
                    placeholder="Your email"
                    required
                  />
                  <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                  <div className="invalid-tooltip position-absolute top-100 start-0">
                    Please provide a valid email address.
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-5 offset-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
              <div id="footer-links" className="row">
                <div className="col-lg-5">
                  <h6 className="mb-2">Our Services</h6>
                  <div id="useful-links" className="collapse d-lg-block" data-bs-parent="#footer-links">
                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                      <li className="nav-item service d-inline-block px-0 pt-1 pb-2">
                        Information Systems Development (ISD)
                      </li>
                      <li className="nav-item service d-inline-block px-0 pt-1 pb-2">Mobile Application Development</li>
                      <li className="nav-item service d-inline-block px-0 pt-1 pb-2">SMS &amp; USSD Development</li>
                      <li className="nav-item service d-inline-block px-0 pt-1 pb-2">Digital Training</li>
                      <li className="nav-item service d-inline-block px-0 pt-1 pb-2">API Development</li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 pt-2 pt-lg-0">
                  <h6 className="mb-2">Our Links</h6>
                  <ul className="nav flex-column pb-lg-1 mb-lg-3">
                    <li className="nav-item address pt-lg-3">
                      <span className="addressBook">
                        <FontAwesomeIcon icon={solid('at')} />
                      </span>
                      Facebook
                    </li>
                    <li className="nav-item address ">
                      <span className="addressBook">
                        <FontAwesomeIcon icon={solid('at')} />
                      </span>
                      Twitter
                    </li>
                    <li className="nav-item address ">
                      <span className="addressBook">
                        <FontAwesomeIcon icon={solid('at')} />
                      </span>
                      Instagram
                    </li>
                    <li className="nav-item address ">
                      <span className="addressBook">
                        <FontAwesomeIcon icon={solid('at')} />
                      </span>
                      GitHub
                    </li>
                    <li className="nav-item address ">
                      <span className="addressBook">
                        <FontAwesomeIcon icon={solid('at')} />
                      </span>
                      LinkedIn
                    </li>
                  </ul>
                </div>

                <div className="col-xl-3 col-lg-3 pt-2 pt-lg-0">
                  <h6 className="mb-2">Contact us</h6>
                  <ul className="nav flex-column pb-lg-1 mb-lg-3">
                    <li className="nav-item address company">OUNIC LIMITED</li>
                    <li className="nav-item address">49294 - 00100 Nairobi, Kenya</li>
                    <li className="nav-item address">
                      <span className="addressBook">
                        <FontAwesomeIcon icon={regular('address-book')} />
                      </span>
                      +254 727 786 885
                    </li>
                    <li className="nav-item address ">
                      <span className="addressBook">
                        <FontAwesomeIcon icon={solid('at')} />
                      </span>
                      ask@ounic.co
                    </li>
                  </ul>
                  <p className="service">Jobs at Ounic</p>
                </div>
              </div>
            </div>
          </div>
          <p className="fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
            <a className="nav-link d-inline-block p-0" href="/" target="_blank" rel="noopener noreferrer">
              &copy; Ounic Limited, 2025
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default FooterComponent;
