import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Products as ProductsGroup } from '../../components/products/Products';
import React from 'react';
import WrapperComponent from '../../components/shared/Wrapper';

const products = [
  {
    id: 1,
    title: 'SaaS Project Management Tool',
    tags: ['Graphic Design'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
  {
    id: 2,
    title: 'Mobile Banking App',
    tags: ['UI/UX'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
  {
    id: 3,
    title: 'Tool for Task Management ',
    tags: ['Motion Design'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
  {
    id: 4,
    title: 'Hospital and Healthcare Website',
    tags: ['Web Design'],
    date: new Date(),
    description:
      'Malesuada eu cursus natoque purus ipsum nunc, velit cras tellus. Maecenas viverra pellentesque at ultrices purus vitae quis erat volutpat. \
        Turpis auctor neque bibendum id pellentesque ut egestas. Donec ut faucibus nisl nec at purus. Cursus vel gravida gravida purus varius \
        feugiat.Semper mauris id adipiscing est. Nam leo rutrum gravida curabitur at vel risus.',
    link: 'https://#',
    image: 'https://placehold.co/600x400',
  },
];
const breadcrumbs = [{ name: 'Our products', isActive: true }];

export const Products = () => {
  return (
    <WrapperComponent>
      <i data-feather="circle"></i>
      <div className="container">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Our products</h1>
        <i data-feather="circle"></i>
      </div>
      <section className="container pb-2 pb-lg-3" style={{ paddingTop: '100px' }}>
        <ProductsGroup products={products} />
      </section>
    </WrapperComponent>
  );
};
