import './person.scss';

import FeatherIcon from 'feather-icons-react';
import React from 'react';

interface Online {
  linkedin: string;
  github?: string;
}

export interface Person {
  name: string;
  role: string;
  online: Online;
  image?: string;
}

export const Person: React.FC<Person> = (props) => {
  return (
    <div className="card card-hover border-0 bg-transparent">
      <div className="position-relative person">
        <img src={props.image ?? 'https://placehold.co/400x400'} className="rounded-3" alt={props.name} />
        <div className="card-img-overlay d-flex flex-column align-items-center justify-content-center rounded-3">
          <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25 rounded-3"></span>
          <div className="position-relative d-flex zindex-2">
            <a href={props.online.linkedin} className="btn btn-icon btn-secondary btn-linkedin btn-sm bg-white me-2">
              <i className="bx bxl-linkedin"></i>
              <FeatherIcon icon="linkedin" size="16" className="fs-lg ms-1" />
            </a>
            {props.online.github ? (
              <a href={props.online.github} className="btn btn-icon btn-secondary btn-github btn-sm bg-white">
                <FeatherIcon icon="github" size="16" className="fs-lg ms-1" />
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="card-body text-center p-3">
        <h3 className="fs-lg fw-semibold pt-1 mb-2">{props.name}</h3>
        <p className="fs-sm mb-0">{props.role}</p>
      </div>
    </div>
  );
};
