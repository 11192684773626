import FeatherIcon from 'feather-icons-react';
import React from 'react';

interface BreadcrumbsProps {
  name: string;
  isActive?: boolean;
}

interface BreadcrumbsGroup {
  breadcrumbs: BreadcrumbsProps[];
}

export const Breadcrumbs: React.FC<BreadcrumbsGroup> = (props) => {
  const breadcrumbs = [{ name: 'Home', isActive: false }, ...props.breadcrumbs];

  return (
    <nav className="pt-md-2 pt-lg-3 pb-4 pb-md-5 mb-xl-4" aria-label="breadcrumb">
      <ol className="breadcrumb mb-0">
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <li key={index} className={'breadcrumb-item' + (breadcrumb.isActive ? ' active' : '')}>
              {index !== 0 ? <FeatherIcon icon="chevrons-right" size="16" className="fs-lg me-1" /> : ''}
              {breadcrumb.name}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
