import * as React from 'react';

import { Link } from 'react-router-dom';
import { RoleCard } from '../person/RoleCard';

enum ArticleType {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

type PortfolioType = {
  articleType: ArticleType;
  image: string;
  tags: string[];
  title: string;
  date: string; // Fix(): Change to accomodate time in the formats of '12 hours ago' or '1 day ago'
  articleLink: string;
  authorCard: React.ReactNode;
};

const Tag = (tag: string) => {
  return <span className="badge fs-sm text-nav bg-secondary text-decoration-none">{tag}</span>;
};

const Tags = (tags: string[]) => {
  return tags.map((tag) => Tag(tag));
};

export const PortfolioCard: React.FC<PortfolioType> = (props) => {
  const HorizontalArticle = () => {
    return (
      <article className="card h-100 border-0 shadow-sm">
        <div className="position-relative">
          <a
            href={props.articleLink}
            className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
          >
            <i className="bx bx-bookmark"></i>
          </a>
          <img src={props.image} className="card-img-top" alt={props.title} />
        </div>
        <div className="card-body pb-4">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <a href="/" className="badge fs-sm text-nav bg-secondary text-decoration-none">
              Digital
            </a>
            <span className="fs-sm text-muted">{props.date}</span>
          </div>
          <h3 className="h5 mb-0">
            <Link to={props.articleLink}>{props.title}</Link>
          </h3>
        </div>
        <div className="card-footer py-4">{props.authorCard}</div>
      </article>
    );
  };

  const VerticalArticle = () => {
    return (
      <article className="card border-0 shadow-sm overflow-hidden">
        <div className="row g-0">
          <div
            className="col-sm-5 position-relative bg-position-center bg-repeat-0 bg-size-cover"
            style={{
              backgroundImage: `url(${props.image})`,
              minHeight: '16rem',
            }}
          >
            <a
              href={props.articleLink}
              className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
            >
              <i className="bx bx-bookmark"></i>
            </a>
          </div>
          <div className="col-sm-7">
            <div className="card-body">
              <div className="d-flex align-items-center mb-3">
                <span className="badge fs-sm text-nav bg-secondary text-decoration-none">Design</span>
                <span className="fs-sm text-muted border-start ps-3 ms-3">{props.date}</span>
              </div>
              <h3 className="h5">
                <Link to={props.articleLink}>{props.title}</Link>
              </h3>
              <hr className="my-4" />
              <div className="d-flex flex-sm-nowrap flex-wrap align-items-center justify-content-between">
                {props.authorCard}
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  };

  return props.articleType === ArticleType.Horizontal ? <HorizontalArticle /> : <VerticalArticle />;
};
