import { codeofafrica, cuendo, giz, strathmore } from '../../common/customers';

import React from 'react';

interface Customers {
  name: string;
  logo: string;
  url: string;
}

const customers: Customers[] = [
  {
    name: 'Code of Africa',
    logo: codeofafrica,
    url: 'https://www.codeofafrica.com/',
  },
  {
    name: 'Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH',
    logo: giz,
    url: 'https://www.giz.de/en/html/index.html',
  },
  {
    name: 'Strathmore University',
    logo: strathmore,
    url: 'https://www.strathmore.edu/',
  },
  {
    name: 'Cuendo',
    logo: cuendo,
    url: 'https://cuendo.com/',
  },
];

export const Clients = () => {
  return (
    <section className="container mb-5" style={{ paddingTop: '40px' }}>
      <div className="row gy-4 py-xl-2">
        <div className="col-md-4">
          <h2 className="mb-0 text-md-start text-center">Our work has been featured by</h2>
        </div>
        <div className="col-lg-7 offset-lg-1 col-md-8">
          <div className="row row-cols-sm-4 row-cols-2 gy-4">
            {customers.map((customer, index) => (
              <div className="col" key={index}>
                <div className="position-relative text-center">
                  <img src={customer.logo} width="100" alt={customer.name} className="d-block mx-auto mb-3" />
                  <a
                    href={customer.url}
                    className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                    target="_blank"
                  >
                    {customer.name}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
