import { type Person, Person as PersonComponent } from './person/Person';
import React from 'react';

interface People {
  people: Person[];
}

export const People: React.FC<People> = (props) => {
  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
      {props.people.map((person, index) => {
        return (
          <div key={index} className="col">
            <PersonComponent {...person} />
          </div>
        );
      })}
    </div>
  );
};
